import HttpBaseError from './HttpBaseError';
import * as Sentry from '@sentry/browser';
import HttpAuthenticationTimeoutError from './HttpAuthenticationTimeoutError';

type LogError =
    | Error
    | HttpBaseError
    | HttpAuthenticationTimeoutError
    | unknown;

const logError = (error: LogError): void => {
    logToConsole(error);
    if (error instanceof HttpAuthenticationTimeoutError) return;
    logToSentry(error);
};

const logToConsole = (error: LogError): void => {
    console.group('Error logged!');
    console.error(error);
    console.groupEnd();
};

const logToSentry = (error: LogError): void => {
    Sentry.captureException(error);
};

export default logError;
