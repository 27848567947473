import { homeUrl } from '../helpers/createUrlHelper';

export default class HttpAuthenticationTimeoutError extends Error {
    constructor(traceId: string) {
        super(traceId);
        if (Error?.captureStackTrace) {
            Error.captureStackTrace(this, HttpAuthenticationTimeoutError);
        }
        this.name = 'HttpAuthenticationTimeoutError';
        Object.setPrototypeOf(this, HttpAuthenticationTimeoutError.prototype);
        location.replace(homeUrl('/401'));
    }
}
